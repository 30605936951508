import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="expandable"
export default class extends Controller {
  static targets = ["container", "button"]

  isExpanded() {
    return this.containerTarget.classList.contains("expanded")
  }

  expand() {
    this.containerTarget.classList.remove("collapsed")
    this.containerTarget.classList.add("expanded")
    this.buttonTarget.innerText = "Show less"
  }

  collapse() {
    this.containerTarget.classList.remove("expanded")
    this.containerTarget.classList.add("collapsed")
    this.buttonTarget.innerText = "Show more"
  }

  toggle() {
    if (this.isExpanded()) {
      this.collapse()
    } else {
      this.expand()
    }
  }
}
