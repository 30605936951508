import { Controller } from "@hotwired/stimulus"

// Use this when you want to wait for a turbo frame to complete its request
// before submitting the form that you add this controller to. An example is an
// auto-saving form in a frame and a button outside of that frame that needs to
// send a request indicating the form is "complete". It must wait for any
// pending auto-saves to occur before its submit in order to ensure that the
// data the user entered is considered. - Aaron, Fri Jun 10 2022

// |--- Turbo frame submit start
// |
// | |- Click submit on synchronized form (synchronized form cancels the submission)
// | |
// | |
// |-+- Turbo frame submit end
//   |- Synchronized form resubmits

// Usage example:
//
// = synchronized_button_to "Submit synchronized form",
//     examples_collaborative_form_synchronized_form_path,
//     class: "primary",
//     turbo_frame_id: "collaborative-form-frame",
//     debug: true
//
// Set synchronized_form_debug_value to true to observe and diagnose

// Connects to data-controller="synchronized-form"
export default class extends Controller {
  static values = {
    turboFrameId: String,
    debug: Boolean
  }

  connect() {
    this.debug("Connect")
    this.synchronize = this.synchronize.bind(this)

    this.element.addEventListener("submit", this.synchronize, true)
  }

  disconnect() {
    this.debug("Disconnect")
    this.element.removeEventListener("submit", this.synchronize, true)

    if (this.listener) {
      this.debug("Removing turbo:submit-end listener")

      const turboFrame = document.getElementById(this.turboFrameIdValue)
      turboFrame?.removeEventListener("turbo:submit-end", this.listener)
      this.listener = null
    }
  }

  synchronize(event) {
    const turboFrame = document.getElementById(this.turboFrameIdValue)

    if (!turboFrame || turboFrame.tagName !== "TURBO-FRAME") {
      this.warn(`Could not find turbo-frame with ID ${this.turboFrameIdValue}`)

      return
    }

    if (this.listener) {
      this.debug("Removing turbo:submit-end listener")

      // This is a "once" listener, but we only want to resume the most recent
      // submission, so we ignore the others - Aaron, Fri Jun 10 2022
      turboFrame.removeEventListener("turbo:submit-end", this.listener)
    }

    if (this.isTurboFrameBusy(turboFrame)) {
      this.debug("Suspending submission")

      event.preventDefault()

      const form = event.target
      const submitter = event.submitter
      submitter?.setAttribute("disabled", "")

      const submitForm = () => {
        submitter?.removeAttribute("disabled")
        form.requestSubmit(submitter)
      }

      turboFrame.addEventListener("turbo:submit-end", submitForm, { once: true })

      this.listener = submitForm
    } else {
      this.debug(`Allowing submission`)
    }
  }

  isTurboFrameBusy(turboFrame) {
    const busy = turboFrame.getAttribute("busy") != null

    const collaborativeFormController = this.application.getControllerForElementAndIdentifier(turboFrame, "collaborative-form")

    const updating = collaborativeFormController?.isUpdating()

    this.debug(`isTurboFrameBusy - busy=${busy}, updating=${updating}`)

    return busy || updating
  }

  debug(message) {
    if (this.debugValue) {
      console.log("Synchronized form - " + message)
    }
  }

  warn(message) {
    console.warn("Synchronized form - " + message)
  }
}
