import { Controller } from "@hotwired/stimulus"

// Set dynamic_form_discard_changes_confirmation_debug_value to true to observe and diagnose

const confirmationText = "Changes you made will not be saved"

// Connects to data-controller="dynamic-form-discard-changes-confirmation"
export default class extends Controller {
  static values = {
    debug: Boolean
  }

  connect() {
    this.debug("Connect")

    this.changed = false
    this.allowNavigation = false

    this.element.addEventListener("change", this.change)
    document.addEventListener("click", this.click, { capture: true })
    document.addEventListener("turbo:visit", this.turboVisit)
    window.addEventListener("beforeunload", this.beforeUnload)
  }

  disconnect() {
    this.debug("Disconnect")

    this.element.removeEventListener("change", this.change)
    document.removeEventListener("click", this.click, { capture: true })
    document.removeEventListener("turbo:visit", this.turboVisit)
    window.removeEventListener("beforeunload", this.beforeUnload)
  }

  change = () => {
    this.changed = true
  }

  click = (event) => {
    const targetId = event.target.id
    if (targetId === "cancel") {
      this.allowNavigation = true
      return
    }

    if (!this.changed) {
      return
    }

    const targetLink = event.target.closest("a")

    if (targetLink == null) {
      return
    }

    const confirmed = confirm(confirmationText)

    if (confirmed) {
      this.allowNavigation = true
    } else {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  // If the page being navigated to includes the meta tag to indicate that turbo
  // should reload the page, rather than proceeding with the turbo navigation,
  // then the page reload will trigger beforeunload.
  // At this point, we no longer need to protect against discarding changes because either the
  // form has been successfully submitted and the redirect is occurring, or the user clicked on
  // a turbo link and there weren't any unsaved changes.
  turboVisit = (event) => {
    window.removeEventListener("beforeunload", this.beforeUnload)
  }

  beforeUnload = (event) => {
    if (!this.changed) {
      return
    }

    if (this.allowNavigation) {
      return
    }

    event.preventDefault()

    // Support for older browsers
    event.returnValue = confirmationText
    return confirmationText
  }

  debug(message) {
    if (this.debugValue) {
      console.log("Dynamic form discard changes confirmation - " + message)
    }
  }
}
